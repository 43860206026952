import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import QuoteForm from "components/forms/QuoteForm.js"


export default () => {
  return (
    <>
      <Header />
      <QuoteForm />
      <Footer />
    </>
  );
};