import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-lg`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default ({
  subheading = "",
  heading = "Shopify Partners",
  description = "Shopify is the leading eCommerce platform, trusted by over a million businesses worldwide. Renowned for its user-friendly interface, robust features, and scalable solutions, Shopify empowers entrepreneurs to build, manage, and grow their online stores with ease. As a Shopify Partner, we harness the power of Shopify's robust backend, secure payment options, and seamless app integrations to create custom, tailored solutions for your business. Partner with us to experience the full potential of Shopify while enjoying a custom-designed eCommerce solution that sets your business apart.",
  stats = [
    {
      key: "Clients",
      value: "2500+",
    },
    {
      key: "Revenue",
      value: "$100M+",
    },
    {
      key: "Employees",
      value: "150+",
    },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>

      </ContentWithPaddingXl>
    </Container>
  );
};


/*

        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>

        */