import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ from "components/faqs/SingleCol.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial"
import Features from "components/features/ThreeColCenteredStatsPrimaryBackground"
import Features2 from "components/features/ThreeColWithSideImageWithPrimaryBackground"

export default () => {
  return (
    <>
      <Header />
      <Hero />

      <AnimationRevealPage>
        <Features />
        <Features2 />
        <FAQ />
      </AnimationRevealPage>
      <Footer />

    </>

  );
};
