import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { useLocation } from 'react-router-dom';
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo-white.svg";
import logoNight from "../../images/logo-purple.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIconBase } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

const DropdownContent = styled.div`
  ${tw`hidden pt-2 pb-2 absolute left-0 bg-white min-w-[165px] shadow-lg z-10`}
  & a {
    ${tw`block text-black px-3 py-2 text-base no-underline hover:bg-primary-400`}
  }
`;

export const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 relative cursor-pointer`}
  color: ${({ skyGradient }) => (skyGradient === 'Night Sky' ? "inherit" : "white")};
  &:hover ${DropdownContent} {
    ${tw`block`}
  }
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const SecondaryLink = tw(NavLink)`
  lg:mx-0
  lg:ml-4
  px-8 py-3 rounded bg-green-500 text-gray-100
  hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0 
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-32 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-black`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
  position: absolute;
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const CloseIcon = styled(CloseIconBase)`
  ${tw`w-6 h-6`}
  position: fixed;
  right: 2rem;
  top: 2.3rem;
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg", skyGradient }) => {
  const [isServicesDropdownVisible, setIsServicesDropdownVisible] = useState(false);
  const showServicesDropdown = () => setIsServicesDropdownVisible(true);
  const hideServicesDropdown = () => setIsServicesDropdownVisible(false);
  const location = useLocation();

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLinks = [
    <NavLinks key={1}>
      {location.pathname === '/' && (
        <NavLink href="#pricing" onClick={toggleNavbar}>Pricing</NavLink>
      )}
      <NavLink
        onMouseEnter={showServicesDropdown}
        onMouseLeave={hideServicesDropdown}
        style={{ color: location.pathname === '/' ? 'fff' : '#3C0D99' }}
        onClick={toggleNavbar}
      >
        Services
        {isServicesDropdownVisible && (
          <DropdownContent style={{ outline: 'solid 2px #6415FF', borderRadius: '10px' }}>
            <a href="/web-development" onClick={toggleNavbar}>Custom Website</a>
            <a href="/seo" onClick={toggleNavbar}>SEO</a>
            <a href="/ppc-campaigns" onClick={toggleNavbar}>PPC Advertising</a>
            <a href="/ecommerce" onClick={toggleNavbar}>Ecommerce</a>
          </DropdownContent>
        )}
      </NavLink>
      <NavLink href="/industries" style={{ color: location.pathname === '/' ? 'fff' : '#3C0D99' }} onClick={toggleNavbar}>Industries</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/contact-us" onClick={toggleNavbar}>Contact Us</PrimaryLink>
      <SecondaryLink css={roundedHeaderButton && tw`rounded-full`} href="/profile" onClick={toggleNavbar}>Client Portal</SecondaryLink>
    </NavLinks>
  ];

  const defaultLogoLink = (
    <LogoLink>
      <a href="/">
        <img src={location.pathname === '/' ? logo : logoNight} alt="logo" />
      </a>
    </LogoLink>
  );

  // CUSTOM STYLES //
  const headerPadding = {
    padding: "3rem 0",
    width: "100%",
  };
  // END OF CUSTOM STYLES //
  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header style={headerPadding} className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer className='fix-absolute' css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"} aria-label={showNavLinks ? "Close menu" : "Open menu"}>
          {showNavLinks ? <CloseIcon /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
