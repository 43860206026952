import { useAuth0 } from '@auth0/auth0-react';
import tw from "twin.macro";
import backgroundImage from '../images/purplebg.webp'

const InButton = tw.button`
lg:mx-0
px-8 py-3 rounded bg-green-500 text-gray-100
hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline
border-b-0 no-underline
`
const backgroundImg = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
    paddingTop: '10rem',

}





const profileWrapper = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    padding: '3rem',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    color: '#e0e0e0',
    fontSize: '2rem',
    textAlign: 'center',
}



const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        !isAuthenticated && (
            <>
                <InButton onClick={() => loginWithRedirect()}>
                    Sign In
                </InButton>
                <section style={backgroundImg}>
                    <div style={profileWrapper}>


                        <h2><strong>Sign in to request site updates</strong></h2>



                    </div>

                </section>
            </>

        )
    )
}

export default LoginButton