import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundImage from "../../images/portland_landscape.webp";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import Sky from '../Sky';
import './styles.css'
import './hero.css'

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${backgroundImage});
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-black text-indigo-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const paddingBottom = {
  paddingBottom: "100px",
};

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const [skyGradient, setSkyGradient] = useState(''); // Initialize the skyGradient state
 

  // Function to handle changes in the skyGradient state
  const handleSkyGradientChange = (newSkyGradient) => {
    setSkyGradient(newSkyGradient);
  };

  return (
    <Container>
      <Sky skyGradient={skyGradient} onSkyGradientChange={handleSkyGradientChange} />
      <HeroContainer>
        <StyledHeader />
        <Content style={paddingBottom}>
     
          <Heading>
        
            <div class="logo"><b><span>P</span>ortland </b>
            <b> Oregon</b>
           </div> 
    
            Web Design & Development
            <br/>
            For Small Businesses 
          </Heading>
      
          <a href="/quote"><PrimaryAction>Get A Quote</PrimaryAction></a>
          <div><br></br></div>
        </Content>
      </HeroContainer>
    </Container>
  );
};
