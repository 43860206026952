import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import Research from '../images/research.webp'
import Strategy from '../images/strategy.webp'
import Design from '../images/Design.webp'
import Prototype from '../images/Prototype.png'
import SEO from '../images/SEO.png'
import Migration from '../images/Migration.png'
import QA from '../images/QA.png'
import Support from '../images/Support.svg'



const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-4xl`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Bullets = styled.ul`
  ${tw`mt-4 list-disc list-inside text-sm leading-loose`}
`;
const Bullet = tw.li``;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const cards = [
        {
            imageSrc: Research,
            subtitle: "",
            title: "Discovery & Research",
            description:
                "We start by diving deep into understanding your unique vision and goals. This critical phase involves gathering insights about your brand, target audience, and competitors. By identifying your objectives, we lay the groundwork for a tailored web development strategy that aligns perfectly with your brand.",
            bullets: [
                "Client Consultation: We listen to your needs and ideas.",
                "Market Research: Analyzing competitors and industry trends.",
                "Goal Setting: Defining clear objectives for your website."
            ],
            url: ""
        },

        {
            imageSrc: Strategy,
            subtitle: "",
            title: "Strategy & Planning",
            description:
                "From our findings, we craft a comprehensive web strategy that serves as the blueprint for your website's success. We cover every aspect, from functionality to user experience, ensuring your website not only meets your current needs but also sets the stage for future growth.",
            bullets: [
                "Site Architecture: Planning the structure and navigation.",
                "Content Strategy: Organizing content for maximum impact.",
                "Technology Stack: Selecting the best tools and platforms."
            ],
            url: ""
        },

        {
            imageSrc: Design,
            subtitle: "",
            title: "Design Concepts",
            description:
                "Our creative team brings your vision to life with innovative and visually appealing design concepts. We ensure that your website's aesthetics perfectly align with your brand identity, engaging your audience effectively.",
            bullets: [
                "Visual Design: Crafting a unique and attractive design.",
                "User Interface (UI): Ensuring intuitive and user-friendly navigation.",
                "Responsive Design: Optimizing for all devices and screen sizes"
            ],
            url: ""
        },
        {
            imageSrc: Prototype,
            subtitle: "",
            title: "Development & Prototyping",
            description:
                "Once the design is approved, our development team brings it to life with pixel-perfect execution. We build a prototype to ensure your website functions seamlessly and efficiently, providing a smooth user experience.",
            bullets: [
                "Front-End Development: Implementing the visual design with clean code.",
                "Back-End Development: Building the server, database, and application logic.",
                "Integration: Adding necessary third-party tools and APIs."
            ],
            url: ""
        },
        {
            imageSrc: SEO,
            subtitle: "",
            title: "Search Engine Optimization (SEO)",
            description:
                "SEO is essential for your website's visibility and success. We optimize your site for search engines to ensure it attracts genuinely interested visitors, leading to higher engagement and conversion rates.",
            bullets: [
                "Keyword Research: Identifying the best keywords for your business.",
                "On-Page SEO: Optimizing content, meta tags, and images.",
                "Technical SEO: Enhancing site speed, mobile-friendliness, and crawlability."
            ],
            url: ""
        },
        {
            imageSrc: Migration,
            subtitle: "",
            title: "Content Migration",
            description:
                "If you have existing content, we ensure a smooth transition to your new website while maintaining the integrity of your information and assets. This step ensures your brand’s essence remains consistent and dependable.",
            bullets: [
                "Content Audit: Reviewing and organizing current content.",
                "Migration Plan: Strategically moving content to the new site.",
                "Preservation: Maintaining SEO rankings and user experience."
            ],
            url: ""
        },
        {
            imageSrc: QA,
            subtitle: "",
            title: "Quality Assurance & Launch",
            description:
                "Before launching, we conduct rigorous testing and refinement to ensure your website is flawless. This meticulous QA process guarantees an exceptional user experience right from the start.",
            bullets: [
                "Testing: Checking for functionality, performance, and security.",
                "Feedback: Incorporating client feedback and final adjustments.",
                "Launch: Making your website live to the world."
            ],
            url: ""
        },
        {
            imageSrc: Support,
            subtitle: "",
            title: "Ongoing Support & Iteration",
            description:
                "Our commitment to your website doesn’t end at launch. We provide ongoing support to keep your website fresh and relevant through iterative enhancements. We continuously improve your site based on the latest trends and user feedback.",
            bullets: [
                "Maintenance: Regular updates and security checks.",
                "Performance Monitoring: Analyzing site performance and making adjustments.",
                "Enhancements: Adding new features and improvements over time."
            ],
            url: ""
        }
    ];

    return (
        <Container>
            <Header />
            <SingleColumn>
                <HeadingInfoContainer>
                    <HeadingTitle>Our Web Development Process</HeadingTitle>
                    <HeadingDescription>
                        At Bridge City Webmasters, we are dedicated to transforming your vision into a dynamic online presence. Our meticulous web development process ensures that your website is not only visually stunning but also highly functional, optimized for search engines, and tailored to meet your business needs.
                    </HeadingDescription>
                </HeadingInfoContainer>

                <Content>
                    {cards.map((card, i) => (
                        <Card key={i} reversed={i % 2 === 1}>
                            <Image imageSrc={card.imageSrc} />
                            <Details>
                                <Subtitle>{card.subtitle}</Subtitle>
                                <Title>{card.title}</Title>
                                <Description>{card.description}</Description>
                                <Bullets>
                                    {card.bullets.map((bullet, index) => (
                                        <Bullet key={index}>{bullet}</Bullet>
                                    ))}
                                </Bullets>

                            </Details>
                        </Card>
                    ))}
                </Content>
            </SingleColumn>
            <SvgDotPattern1 />
            <SvgDotPattern2 />
            <SvgDotPattern3 />
            <SvgDotPattern4 />
            <Footer />
        </Container>
    );
};
