import React, { Suspense } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Features from "components/features/ThreeColWithSideImage.js";

import macHeroScreenshotImageSrc from "images/laptop1.webp";
import tabletLaptop from "images/laptop2.webp";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import me from '../images/beaux.webp'

import 'react-lazy-load-image-component/src/effects/blur.css';
//Lazy Loaded Elements
const FeatureWithSteps = React.lazy(() => import("components/features/TwoColWithSteps.js"));
const Pricing = React.lazy(() => import("components/pricing/ThreePlansWithHalfPrimaryBackground"));
const MainFeature = React.lazy(() => import("components/features/TwoColWithButton.js"));
const MainFeature2 = React.lazy(() => import("components/features/TwoColWithTwoHorizontalFeaturesAndButton.js"));
const PortfolioCards = React.lazy(() => import("components/cards/PortfolioTwoCardsWithImage"));
const FAQ = React.lazy(() => import("components/faqs/SingleCol"));
const GetStarted = React.lazy(() => import("components/cta/GetStarted"));
const Footer = React.lazy(() => import("components/footers/MiniCenteredFooter"));

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold`;
  const HighlightedText = tw.span`text-primary-500`;
  const HighlightedTextGreen = tw.span`text-green-300`;

  return (
    <>
      <Hero roundedHeaderButton={true} />
      <Features
        //subheading={<Subheading>Features (this is located in demos folder)</Subheading>}
        heading={
          <>
            Website<HighlightedText> Services.</HighlightedText>
          </>
        }
      />
      <Suspense fallback={<div>Loading...</div>}>
        <FeatureWithSteps
          subheading={<Subheading>Services Starting At</Subheading>}
          heading={
            <>
              <HighlightedText>$0</HighlightedText> Down,<HighlightedText>$150</HighlightedText> Per Month,
              <br></br>12 Month Minimum Contract
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <Pricing
          subheading={<HighlightedTextGreen><Subheading>Pricing</Subheading></HighlightedTextGreen>}
          heading={
            <>
              Reasonable & Flexible <HighlightedTextGreen>Plans.</HighlightedTextGreen>
            </>
          }
        />
        <MainFeature
          subheading={<Subheading>Quality Work</Subheading>}
          imageSrc={me}
          imageBorder={true}
          imageDecoratorBlob={true}
        />
        <PortfolioCards></PortfolioCards>
        <MainFeature2
          subheading={<Subheading>VALUES</Subheading>}
          heading={
            <>
              We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
            </>
          }
          imageSrc={tabletLaptop}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Purpose-Driven",
              description: "We believe every website should have a clear purpose. Our approach begins with understanding your unique goals and tailoring your digital experience to serve those needs effectively.",
              iconContainerCss: tw`bg-green-300 text-green-800`
            },
            {
              Icon: BriefcaseIcon,
              title: "Beyond Templates",
              description: "Your business is unique, and your website should reflect that. We steer clear of cookie-cutter WordPress templates, opting instead for custom solutions that highlight your brand's individuality.",
              iconContainerCss: tw`bg-red-300 text-red-800`
            }
          ]}
        />

        <FAQ />
        <GetStarted />
        <Footer />
      </Suspense>
    </>
  );
}


/*
<Testimonial
  subheading={<Subheading>Testimonials</Subheading>}
  heading={
    <>
      Our Clients <HighlightedText>Love Us.</HighlightedText>
    </>
  }
  testimonials={[
    {
      stars: 5,
      profileImageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      heading: "Amazing User Experience",
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      customerName: "Charlotte Hale",
      customerTitle: "Director, Delos Inc."
    },
    {
      stars: 5,
      profileImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      heading: "Love the Developer Experience and Design Principles !",
      quote:
        "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      customerName: "Adam Cuppy",
      customerTitle: "Founder, EventsNYC"
    }
  ]}
/>
*/