import { useAuth0 } from '@auth0/auth0-react';
import tw from "twin.macro";
import backgroundImage from '../images/purplebg.webp'

const backgroundImg = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
    paddingTop: '10rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}
const buttomWrapper = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    margin: '2rem 0'
}

const BugButton = tw.a`
lg:mx-0
px-8 py-3 rounded bg-gray-200 text-primary-400
hocus:bg-green-300 hocus:text-primary-400 focus:shadow-outline
border-b-0 no-underline outline-2 outline-green-500 sm:max-w-xs text-center
`
const UpdateButton = tw.a`
lg:mx-0
px-8 py-3 rounded bg-green-500 text-gray-100
hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline
border-b-0 no-underline sm:max-w-xs text-center
`
const imgStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginBottom: '1rem'
};

const FlexColumn = tw.div`
       flex w-full max-w-xs mx-auto mb-12 justify-between items-center p-2
    rounded-full bg-green-400 shadow-xl relative
    sm:flex-col sm:items-center min-h-12 flex justify-center
`;

/*const flexColumn = {
    width: '100%',
    maxWidth: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '3rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: '50px',
    background: 'linear-gradient(145deg, #dadada, #ffffff)',
    boxShadow: '20px 20px 60px #cecece, -20px -20px 60px #ffffff',
    position: 'relative',
    display: 'flex',
}
*/



const ProfileLogin = () => {
    const { user, isAuthenticated } = useAuth0();

    // Determine the picture URL to use: custom_picture if it exists, otherwise the default picture
    const pictureUrl = user && user.user_metadata && user.user_metadata.custom_picture ? user.user_metadata.custom_picture : user?.picture;

    return (
        isAuthenticated && (
            <section style={backgroundImg}>
                <div>
                {pictureUrl && <img style={imgStyle} src={pictureUrl} alt={user?.name} />}
                </div>
                
                <FlexColumn>
                   
                        <h2 style={{ padding: '0 1rem' }}><strong>Hello {user?.name}!</strong></h2>
                  
                </FlexColumn>
                <div style={buttomWrapper}>
                    <BugButton style={{ outline: 'solid' }} href="https://bugtracker-bridgecity.netlify.app" target="_blank" rel="noopener noreferrer">
                        <span> </span>Report Bug<span> </span>
                    </BugButton>
                    <UpdateButton href="https://bugtracker-bridgecity.netlify.app" target="_blank" rel="noopener noreferrer">
                        Request Update
                    </UpdateButton>
                </div>



            </section>
        )
    )
}


export default ProfileLogin