import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/ecom.png";
import WebDesign from "images/webDesign.png";
import CustomizeIconImage from "images/copy-writing.png";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/digital-marketing.png";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ 
  cards = null, 
  heading = "Big", 
  subheading = "Features", 
  description = "Located in Portland, Oregon, Bridge City specializes in crafting personalized web development and user experience design solutions tailored to meet the unique needs of each of our clients." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: WebDesign,
      title: "Web Design",
      description: "We craft websites that harmonize style and functionality, achieving online success through a perfect blend of creativity and technology."
    },
    { 
      imageSrc: SupportIconImage, 
      title: "eCommerce",
      description: "From product catalog management to implementing secure payment gateways, we offer a comprehensive suite of services designed to elevate your online store." 
    },

    { 
      imageSrc: CustomizeIconImage, 
      title: "Content Writing", 
      description: "We deliver compelling and engaging content that captivates your audience. Whether it's web copy, blog posts, or marketing materials, we craft content to drive results."
    },
    { 
      imageSrc: ReliableIconImage, 
      title: "Digital Marketing",
      description: "Our digital marketing services are your key to online success. We'll boost your brand's visibility and engagement, delivering growth through effective strategies and campaigns." 
    },

    { imageSrc: FastIconImage, 
      title: "Branding",
      description: "We are dedicated to shaping a powerful, memorable brand image for your business. We create compelling visuals and narratives that leave a lasting impact."
    },
    { imageSrc: SimpleIconImage, 
      title: "Webmaster Services", 
      description: "We ensure your website runs seamlessly, allowing you to focus on your core business. We handle everything from updates and maintenance to troubleshooting."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Paragraph located ThreeColWithSideImage."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
