import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
//import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import EcomPage from "pages/Ecommerce.js";
import IndustryPage from "pages/Industries.js";
// import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
import history from "./utils/history";
import ComponentRenderer from "ComponentRenderer.js";
//import MainLandingPage from "MainLandingPage.js";
//import ThankYouPage from "ThankYouPage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Profile from './components/Profile.js';
import ProfileLogin from "components/ProfileLogin";
import GoogleAdsPage from "pages/GoogleAd";
import SEO from "pages/SEO";
import QuoteForm from "pages/Quote";
import WebDev from "pages/WebDev";


export default function App() {
  const overflowHidden = {
    overflow: "hidden",
  };

  return (
    <AnimationRevealPage disabled>
      <GlobalStyles />
      <Router history={history}>
        <Routes>
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/ecommerce" element={<EcomPage />} />
          <Route path="/industries" element={<IndustryPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile-login" element={<ProfileLogin />} />
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/" element={<SaaSProductLandingPage style={overflowHidden} />} />
          <Route path="/ppc-campaigns" element={<GoogleAdsPage />} />
          <Route path="/seo" element={<SEO />} />
          <Route path="/quote" element={<QuoteForm />} />
          <Route path="/web-development" element={<WebDev />} />
        </Routes>
      </Router>
    </AnimationRevealPage>
  );
}
