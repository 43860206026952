import React from "react";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";

const GoogleAdsPage = () => {
  return (
    <div>
      <style>
        {`
          .main-area {
            display: flex;
    
          }

          .page-content {
            margin: 0 auto;
            padding-bottom: 2rem;
          }

          .pricing-section,
          .details-section {
            max-width: 1200px;
            background-color: #f8f8f8;
          }
        

          .top-module{
                    background-color: #fff;
                    padding: 1rem;
          }

          .top-module p{
          padding: 1rem;
 
          }

          .pricing-row,
          .details-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

          }

          .pricing-column,
          .details-column {
                    width: 100%;
          }

          .pricing-card {
            text-align: center;
            padding: 20px;
            background-color: #e1e3e3;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
          }

          .module-header {
            font-size: 2em;
            margin-bottom: 10px;

          }

          .pricing-card p,
          .cta-module p {
            margin: 0;
            margin-bottom: 10px;
          }

          .mt-4{
          margin-top: 1rem;
          }

          .image-module img {
            max-width: 100%;
            height: auto;
          }

          .additional-info {
            padding: 20px;
            background-color: #fff;
            font-size: 14px;
          }

          .button-module {
            text-align: center;
          }

          .button {
            display: inline-block;
            padding: 10px 20px;
            color: #fff;
            background-color: #007bff;
            border-radius: 4px;
            text-decoration: none;
            transition: background-color 0.3s;
          }

          .button:hover {
            background-color: #0056b3;
          }

          @media (max-width: 980px) {
            .pricing-column,
            .details-column {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        `}
      </style>
      <>
        <Header />
        <section className="main-area">
          <article className="page-content">
            <div className="pricing-section">
              <div className="pricing-row">
                <div className="pricing-column">
                  <div className="pricing-card">
                    <div className="top-module">
                      <h1 className="module-header">Google Ad Spend</h1>
                      <p>We aim to maximize the efficiency of your budget. Understanding that your Google ad spend is influenced by numerous factors,
                        we conduct thorough research to identify the optimal pricing strategy. Beware of those who offer
                        quotes without analyzing your market—they are likely wasting both your time and money.
                        Our goal is to ensure you achieve the best possible return on your investment. Generally, you can expect to pay a minimum of $300/mo just for ad spend.
                        We value transparency.
                      </p>
                      <h2 style={{ fontSize: '1.1em', fontWeight: '600' }}>If you're tired of marketing agencies whose only proposal is to increase ad spend without explaining why, you've come to the right place.</h2>
                    </div>
                    <h2 className="module-header mt-4">Managed Fee</h2>
                    <h3 className="module-header">$250 Monthly</h3>
                    <p><span style={{ color: '#000000' }}>+ $399.95 Setup Fee (One Time)</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-section">
              <div className="details-row">
                <div className="details-column">
                  <div className="cta-module">
                    <h2 className="module-header"> Proudly Not A Google Partner</h2>
                    <p>At <strong>Bridge City Webmasters</strong>, we prioritize delivering measurable outcomes and genuine value to our clients <strong>NOT</strong> merely increasing ad spend.
                      This commitment is the primary reason we have chosen not to become Google Partners.
                      While Google Partnership status often emphasizes the volume of ad spend, we believe this focus can detract from what truly matters: achieving our clients' business goals. Our approach centers on strategic, effective advertising that maximizes return on investment, rather than simply meeting spend thresholds.
                      By not aligning ourselves with a program that prioritizes spending, we ensure that our efforts remain client-centric, transparent, and results-driven, upholding the values that define our services.

                    </p>

                    <h2 className="module-header">Pay Per Click Management</h2>
                    <p>In today’s world of rapid change and technological evolution, there aren’t enough hours in the day to accomplish everything you want to do.
                      Our digital marketing strategists at Bridge City Webmasters can help you with your marketing goals.
                      We analyze competitors and conduct thorough keyword research to ensure your organic search is performing optimally, reducing your PPC costs. This involves studying user behavior and identifying negative keywords to prevent clicks from non-customers.
                      Our AdWords campaign managers have years of experience, and they know how to launch and maintain a successful digital marketing campaign. They are capable, ready, and willing to help you get your name out to the world.</p>
                  </div>
                </div>
              </div>
              <div className="details-row">
                <div className="details-column">
                  <div className="cta-module">
                    <h2 className="module-header">About Google Ads (Formally AdWords)</h2>
                    <p>At <strong>Bridge City Webmasters</strong>, we have been managing successful <strong>online marketing campaigns</strong> for small, medium, and large businesses for over a decade. Throughout this time, we have gained invaluable knowledge and experience from numerous campaigns.</p>
                    <p>We understand that all marketing campaigns have budget constraints. The key to creating a <strong>cost-effective campaign</strong> is not only knowing when and how much to bid on your <strong>Ads</strong>, but also having a robust <strong>Organic SEO</strong> foundation. Our team will ensure you achieve a strong ranking with your Organic SEO, regardless of whether your <strong>Google Ads</strong> are running. It's important to note that <strong>Organic SEO</strong> is not an instant fix.</p>
                    <p>It requires time, research, and dedicated effort from web developers to succeed. When properly implemented, your <strong>Organic SEO</strong> will ensure that your brand can be found in <strong>Search Engine Results</strong> regardless of your paid advertising budget or ad schedule.</p>
                  </div>

                </div>
                <div className="details-column">

                  <div className="cta-module">
                    <h2 className="module-header">Monthly, Fixed Budget</h2>
                    <p>We provide professional guidance and you decide the budget limitations.</p>
                  </div>
                  <div className="cta-module">
                    <h2 className="module-header">Simple Reporting</h2>
                    <p>Monthly or real-time reporting can be requested for your advertising campaign</p>
                  </div>
                  <div className="cta-module">
                    <h2 className="module-header">Ad Targetability</h2>
                    <p>Reach your ideal customers with both geographic and demographic ad targeting.</p>
                  </div>
                  <div className="cta-module">
                    <h2 className="module-header">Local Targeting</h2>
                    <p>Ads can be targeted to specific locations, ensuring your ads reach only your chosen local market.</p>
                  </div>
                </div>
              </div>
            </div>

          </article>
        </section>
        <Footer />
      </>
    </div >

  );
};

export default GoogleAdsPage;
