import { useAuth0 } from '@auth0/auth0-react';
import Header from '../components/headers/light'
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import Loading from './Loading';
import ProfileLogin from './ProfileLogin';
import Footer from './footers/MiniCenteredFooter'

const Profile = () => {
    const { isLoading, error } = useAuth0();

    return (
        <section>
            <Header />
            {error && <p>Authentication Error</p>}
            {!error && isLoading && <Loading />}
            {!error && !isLoading && (
                <>
                    <LoginButton />
                    <LogoutButton />
                    <ProfileLogin />
                </>
            )}
            <Footer />
        </section>


    )
}

export default Profile


