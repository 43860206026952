import React, {useEffect} from 'react'
import './nightsky.css';


const Sky = ({ skyGradientProp, onSkyGradientChange }) => {

  // You can use a function to calculate the sky gradient based on the time of day
  const calculateSkyGradient = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 0 && hours < 6) {
      return 'linear-gradient(180deg, #FBB03B, #ED1C24)';  // Morning
    } else if (hours >= 6 && hours < 17) {
      return 'Night Sky'
      //return 'linear-gradient(to bottom, lightblue, skyblue)' // Evening
    } else {
      return 'Night Sky'; // Use skyGradient as provided; // Midnight
    }
  };

  useEffect(() => {
    // Calculate and update the sky gradient when the component mounts or when the external prop changes
    const newSkyGradient = calculateSkyGradient();
    onSkyGradientChange(newSkyGradient);
  }, [onSkyGradientChange]);

  const skyContent = calculateSkyGradient();

  if (skyContent === 'Night Sky') {
    return (
    <div className="stars-wrapper">
    <svg
      className="stars"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
    >
      {/* Create stars */}
      {[...Array(200)].map((_, i) => (
        <circle
          key={i}
          className="star"
          cx={`${Math.round(Math.random() * 10000) / 100}%`}
          cy={`${Math.round(Math.random() * 10000) / 100}%`}
          r={`${Math.round((Math.random() + 0.5) * 10) / 10}`}
        />
      ))}
    </svg>

    <svg className="extras" width="100%" height="100%" preserveAspectRatio="none">
      <defs>
        <radialGradient id="comet-gradient" cx="0" cy="50%" r="50%">
          <stop offset="0%" stopColor="rgba(255,255,255,.8)" />
          <stop offset="100%" stopColor="rgba(255,255,255,0)" />
        </radialGradient>
      </defs>

      {/* Create comet A */}
      <g transform="rotate(-135)">
        <ellipse
          className="comet comet-a"
          fill="url(#comet-gradient)"
          cx="0"
          cy="0"
          rx="150"
          ry="2"
        />
      </g>

      {/* Create comet B */}
      <g transform="rotate(20)">
        <ellipse
          className="comet comet-b"
          fill="url(#comet-gradient)"
          cx="100%"
          cy="0"
          rx="150"
          ry="2"
        />
      </g>

      {/* Create comet C */}
      <g transform="rotate(300)">
        <ellipse
          className="comet comet-c"
          fill="url(#comet-gradient)"
          cx="40%"
          cy="100%"
          rx="150"
          ry="2"
        />
      </g>
    </svg>
  </div>
  )} else {
    return (
      <div
        style={{
          background: skyGradientProp,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      />
    );
  }
};


export default Sky;
