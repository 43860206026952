import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { Auth0Provider } from '@auth0/auth0-react';

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);


// Set up the Auth0Provider with your Auth0 credentials
root.render(
  <Auth0Provider
        domain= "dev-ynjcn3fg0keaf00i.us.auth0.com"
        clientId="FjtO0Nn35GPxKQf4Dw09YUI04Jsh5dQf"
        authorizationParams={{
          redirect_uri: "https://portlandwebdesign.netlify.app/profile",
        }}
        >
    <App />
  </Auth0Provider>
);
