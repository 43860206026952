import React from "react";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";

const IndustriesPage = () => {
  return (
    <div>
      <style>
        {`
          .main-area {
            flex: 1;
          }

          .page-content {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
          }

          .section {
            padding: 20px;
            background-color: #f8f8f8;
            margin-bottom: 20px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .section h2 {
            font-size: 24px;
            margin-bottom: 10px;
          }

          .section p {
            margin: 0 0 10px;
            line-height: 1.6;
          }

          .industries-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .industry-card {
            width: 30%;
            margin-bottom: 20px;
            padding: 15px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .industry-card h3 {
            font-size: 20px;
            margin-bottom: 10px;
          }

          .industry-card p {
            margin: 0;
          }

          @media (max-width: 980px) {
            .industry-card {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        `}
      </style>
      <>
        <Header />
        <section className="main-area">
          <article className="page-content">
            <div className="section">
              <h2>Industries We Serve</h2>
              <p>At <strong>Bridge City Webmasters</strong>, we cater to a wide range of industries, providing tailored solutions to meet the unique needs of each sector.<br></br> Our expertise includes, but not limited to the following industries:</p>
            </div>

            <div className="section industries-list">
              <div className="industry-card">
                <h3>Healthcare</h3>
                <p>Our healthcare solutions focus on enhancing patient engagement, streamlining administrative processes, and improving overall healthcare delivery through effective digital strategies.</p>
              </div>

              <div className="industry-card">
                <h3>Retail</h3>
                <p>We help retail businesses increase their online presence, drive traffic to their stores, and boost sales through targeted digital marketing and e-commerce solutions.</p>
              </div>

              <div className="industry-card">
                <h3>Finance</h3>
                <p>Our services for the finance industry include optimizing online banking experiences, improving financial product visibility, and ensuring secure, compliant digital practices.</p>
              </div>

              <div className="industry-card">
                <h3>Education</h3>
                <p>We support educational institutions in enhancing student engagement, promoting online courses, and optimizing administrative systems for better efficiency.</p>
              </div>

              <div className="industry-card">
                <h3>Technology</h3>
                <p>Our technology industry services focus on boosting innovation, improving product visibility, and driving user engagement through advanced digital marketing strategies.</p>
              </div>

              <div className="industry-card">
                <h3>Hospitality</h3>
                <p>We assist hospitality businesses in attracting more guests, enhancing customer experiences, and optimizing online bookings through tailored digital solutions.</p>
              </div>

              <div className="industry-card">
                <h3>Real Estate</h3>
                <p>Our real estate services aim to increase property visibility, attract potential buyers, and streamline the sales process through effective online marketing strategies.</p>
              </div>

              <div className="industry-card">
                <h3>Legal</h3>
                <p>We provide legal firms with digital marketing strategies to enhance their online presence, attract new clients, and manage their online reputation effectively.</p>
              </div>

              <div className="industry-card">
                <h3>Manufacturing</h3>
                <p>Our manufacturing solutions focus on optimizing supply chain visibility, improving product marketing, and enhancing overall operational efficiency through digital transformation.</p>
              </div>

              <div className="industry-card">
                <h3>Contractor</h3>
                <p>We offer specialized digital marketing solutions for contractors to increase their online visibility, generate leads, and grow their business.</p>
              </div>

              <div className="industry-card">
                <h3>Landscaping</h3>
                <p>Our landscaping industry services focus on showcasing your portfolio, attracting new clients, and enhancing your online presence through targeted digital strategies.</p>
              </div>

              <div className="industry-card">
                <h3>Plumbing</h3>
                <p>We help plumbing businesses improve their online visibility, generate more leads, and grow their customer base with effective digital marketing solutions.</p>
              </div>
            </div>
          </article>
        </section>
        <Footer />
      </>
    </div>
  );
};

export default IndustriesPage;
