import { useAuth0 } from '@auth0/auth0-react';
import tw from "twin.macro";


const OutButton = tw.button`
lg:mx-0
px-8 py-3 rounded bg-green-500 text-gray-100
hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline
border-b-0 no-underline
`

const LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && (
            <OutButton onClick={() => logout()}>
                Sign Out
            </OutButton>
        )
    )
}

export default LogoutButton