import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`max-w-lg mx-auto`;

const ImageColumn = styled(Column)`
  ${tw`md:col-span-1 md:order-1`}
`;

const TextColumn = styled(Column)`
  ${tw`md:col-span-1 md:order-2`}
`;

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-3xl lg:text-4xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12 grid grid-cols-2 gap-4`;
const Step = tw.li`flex items-start`;

const StepNumber = tw.div`font-semibold text-4xl leading-none text-primary-500`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.div`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-secondary-100 font-medium`;
const HighlightedText = tw.span`text-primary-500`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <HighlightedText>Professionals.</HighlightedText>
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = [
    {
      heading: "Hosting Fees Included",
      description: "Starter and Standard site builds always inlcude free hosting."
    },
    {
      heading: "Unlimited Edits",
      description: "Change anything you want at anytime and it will be done within 48 hours."
    },
    {
      heading: "24/7 Customer Service",
      description: "Submit bug reports any time of day; we will solve them right away."
    },
    {
      heading: "Google Analytics",
      description: "We install Analytics for free to monitor traffic and where it comes from."
    },
    {
      heading: "On Page SEO",
      description: "We include free on page SEO services. SEO is not magic, our expertise in data science gives us a distinct edge in optimization."
    },
    {
      heading: "We build it",
      description: "No clunky WordPress themes and dated plugins that break, we only use modern development practices."
    }
  ],
  decoratorBlobCss = null,
}) => (
  <Container>
    <TwoColumn>
      <ImageColumn>
        <Image src={imageSrc} alt="Decorative Image" imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
        {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
      </ImageColumn>
      <TextColumn>
        <TextContent>
          <Subheading>{subheading}</Subheading>
          <Heading>{heading}</Heading>
          <Steps>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepNumber>{(index + 1).toString().padStart(1, '0')}</StepNumber>
                <StepText>
                  <StepHeading>{step.heading}</StepHeading>
                  <StepDescription>{step.description}</StepDescription>
                </StepText>
              </Step>
            ))}
          </Steps>
        </TextContent>
      </TextColumn>
    </TwoColumn>
  </Container>
);
