
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import tw from "twin.macro";


const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300`;


const SEOServicesPage = () => {
    return (
        <div>
            <style>
                {`
          .main-area {
            flex: 1;
          }

          .page-content {
            max-width: 1200px;
            margin: 0 auto;
          }

          .section {
            padding: 20px;
            background-color: #f8f8f8;
            margin-bottom: 20px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .section h2 {
            font-size: 24px;
            margin-bottom: 10px;
          }

          .section p {
            margin: 0 0 10px;
            line-height: 1.6;
          }

          .additional-info {
            padding: 20px;
            background-color: #fff;
            font-size: 14px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
        `}
            </style>
            <>
                <Header />
                <section className="main-area">
                    <article className="page-content">
                        <div className="section">
                            <h2>Our Comprehensive SEO Services</h2>
                            <p>At <strong>Bridge City Webmasters</strong>, we understand that a well-optimized website is crucial for online success.
                                Our comprehensive SEO services are designed to enhance your website's visibility, drive organic traffic,
                                and improve your search engine rankings.</p>
                            <p><strong>Already have a website but need affordable SEO services?</strong> Let us evaluate your current setup. You might be surprised to discover that even sites developed by major agencies can overlook critical SEO functionalities.</p>

                        </div>

                        <div className="section">
                            <h2>Included Services with Any Maintenance Package Purchase</h2>

                            <p>On-page SEO involves optimizing individual web pages to rank higher and earn more relevant traffic in search engines. It includes both the content and HTML source code of a page that can be optimized. Here are the key components of our on-page SEO services:</p>
                            <ul>
                                <li>
                                    <strong>Keyword Research:</strong> We conduct thorough keyword research to identify the most relevant and high-performing keywords for your business. This ensures that your content targets the right audience.
                                </li>
                                <li>
                                    <strong>Title Tags:</strong> Optimizing title tags is essential for search engines to understand the topic of your content. We ensure that each page has a unique and descriptive title tag.
                                </li>
                                <li>
                                    <strong>Meta Descriptions:</strong> We write compelling meta descriptions that improve click-through rates from search engine results pages (SERPs). These descriptions summarize the content of the page and entice users to click.
                                </li>
                                <li>
                                    <strong>Header Tags:</strong> Proper use of header tags (H1, H2, H3) helps organize content and makes it easier for search engines to understand the structure of your page. We ensure that your content is well-structured and optimized.
                                </li>
                                <li>
                                    <strong>Content Optimization:</strong> High-quality, relevant content is the backbone of on-page SEO. We optimize your content to ensure it is informative, engaging, and aligned with your target keywords.
                                </li>
                                <li>
                                    <strong>Image Optimization:</strong> Images are optimized with proper alt tags and file names to improve search engine visibility and accessibility.
                                </li>
                                <li>
                                    <strong>Internal Linking:</strong> Strategic internal linking helps distribute page authority across your site and improves navigation for both users and search engines.
                                </li>
                                <li>
                                    <strong>URL Structure:</strong> We ensure that your URLs are clean, descriptive, and optimized for search engines.
                                </li>
                                <li>
                                    <strong>Mobile Optimization:</strong> With the increasing number of mobile users, it is crucial to have a mobile-friendly website. We optimize your site for mobile devices to ensure a seamless user experience.
                                </li>
                                <li>
                                    <strong>Page Speed:</strong> Fast-loading pages improve user experience and search engine rankings. We optimize your site to ensure quick load times.
                                </li>
                                <li>
                                    <strong>Schema Markup:</strong> Implementing schema markup helps search engines understand the context of your content and can improve your visibility in SERPs.
                                </li>
                            </ul>
                            <p>All these on-page SEO services are included with every website build we provide. This ensures that your site is fully optimized from the start, giving you a solid foundation for success.</p>
                        </div>



                        <div className="additional-info">
                            <p>Ready to boost your online presence? Contact <strong><Link href="/contact-us">Bridge City Webmasters</Link></strong> today to learn more about our SEO services and how we can help your business succeed online.</p>
                        </div>
                    </article>
                </section>
                <Footer />
            </>
        </div>
    );
};

export default SEOServicesPage;
